// import { chunk } from 'lodash'
import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import {    
    // Dropdown, NavItem, NavLink,
    // NavDropdown, Navbar, Nav,
    Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Layout from '../components/layout'
import ProductsList from '../components/products/list'
import ProductsMenu from '../components/products/menu'

import './../css/products.css'
const ptype = require('./../util/productcatelist.json')

// if (typeof window !== `undefined`) {
//     window.productsPostsToShow = 10
// }

class ProductListPage extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        // let postsToShow = 10
        // let _pts = 10
        // if (typeof window !== `undefined`) {
        //     postsToShow = window.productsPostsToShow
        //     _pts = window.productsPostsToShow
        // }
        this.state = {
            // allLocaleData: this._useData(`all`),
            filter: {
                type: 'all',
                date: ''
            },
            productsList: this._useData(`all`),
            // showingMore: postsToShow > _pts,
            postsToShow:4,
            postsToLoad:4,
        }
    }

    componentDidMount() {
        window.addEventListener(`scroll`, this._handleScroll)
        if (typeof window !== 'undefined') {
            const urlHash = window.location.hash.split('#')
            // console.log(`urlHash - `,urlHash)
            const typeSelected = (urlHash.length <= 1) ? 'all' : urlHash[1]
            this._setFilter({type: typeSelected})
        }

        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.hash !== this.props.location.hash) {
            const urlHash = this.props.location.hash.split('#')
            // console.log(`componentDidUpdate urlHash - `,urlHash)
            const typeSelected = (urlHash.length <= 1) ? 'all' : urlHash[1]
            this._setFilter({type: typeSelected})
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
        // window.productsPostsToShow = this.state.postsToShow
    }

    _useData(newsType) {
        // console.log(`_useData / newsType = ${newsType} , this.props.data = `, this.props.data)
        
        let products = {}
        products["all"] = this.props.data.allProducts.edges.map( d=>{
            d.node["label_type"] = +d.node.data.label_type

            let scateId = _.findKey( ptype, { "nameid" : d.node.data.series_id } ) 
            let cateId = _.findKey( ptype, { "nameid" : d.node.data.type_id } ) 
            // console.log(`d.node.data.series_id = scateId = ${scateId} , d.node.data.type_id = cateId = ${cateId} ` )
            if (typeof products[cateId] === 'undefined') products[cateId] = []
            if (typeof cateId != 'undefined') products[cateId].push(d.node)
            if (typeof products[scateId] === 'undefined') products[scateId] = []
            if (typeof scateId != 'undefined') products[scateId].push(d.node)
            
            return d.node
        })
        // console.log(`_useData products = `, products)

        const _d = _.orderBy( products[`${newsType}`],['display_order','label_type'], ['desc', 'desc'] )
        // console.log(`_d = `, _d)
        
        return _d
    }

    _setFilter(options) {
        options = options || {}
        if (options !== {}) {
            const _dd = this._useData(options.type)
            // console.log(`_setFilter = ${options.type} , _dd = `, _dd)
            this.setState({
                // allLocaleData: _dd,
                productsList: _dd,
                filter: {
                    type: options.type
                }
            })
        }
    }

    _update() {
        // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        // let newsType = this.state.filter.type
        // newsType = (newsType !== "all") ? `type`+(newsType.substring(0,1).toUpperCase())+newsType.substring(1, newsType.length) : newsType
        // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight} && pts: ${this.state.postsToShow} < ${_.size(this.state.productsList)} `)
        if (distanceToBottom < window.scrollY+window.innerHeight && this.state.postsToShow < _.size(this.state.productsList)){
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }

    render() {
        const products = this.state.productsList
        const enNews = this.props.data.allProductsEngExclusive.edges.map(d => d.node)
        // console.log(`render products = `, products)
        // console.log(`render this.props.data = `, this.props.data)

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                // let filterType = (this.state.filter.type) ? `${t(products.type_id)} - ${t(ptype[this.state.filter.type])}` : `${t(ptype.all)}`
                const hash = this.state.filter.type
                const cateId = ptype[hash].serieid
                let filterhead = ""
                filterhead += ( cateId === '')    ? '' : `${t(ptype[cateId].nameid)} - `
                // console.log(`cateId = ${cateId} `)
                filterhead += ( hash === 'all')  ? `${t(ptype.all.nameid)}` : `${t(ptype[hash].nameid)}`
                
                
                return (
                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU011")}`, url:this.props.location.href } }>
                    {/* <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000009")}`, url:this.props.location.href, img:this.props.data.siteOGImage.publicURL } }> */}
                        <div id="ProductList" className="body-page ">

                            <nav role="navigation">
                                <Container className="wrapper line-head mb-0">
                                    <Row >
                                        <Col xs={9} lg={12} className="d-flex align-items-center" >
                                            <div className="phead">{t('MU011')}</div>
                                        </Col>
                                        <ProductsMenu mode="dropdown"/>
                                    </Row>
                                </Container>
                            </nav>

                            <Container className="wrapper line-base pt-0">
                                <Row>
                                    <Col xs={12} md={9}>
                                        <main role="main" className="main">
                                            <div className="ptitle">{filterhead}</div>
                                            {/*--=============  Product Area  =============--*/}
                                            <ProductsList products={products} enProducts={enNews} pts={this.state.postsToShow}/>
                                        </main>
                                    </Col>

                                    <Col xs={12} md={3}>
                                        {/*--=============  Side Menu Area  =============--*/}
                                        <ProductsMenu mode="side" className="d-none d-md-block pl-0"/>
                                    </Col>

                                </Row>
                            </Container>

                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(ProductListPage)

export const query = graphql`
    query getProcutsLang($lng: String!, $lngReg: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        allProducts: allPrismicProductDetail(filter: { lang: { regex: $lngReg } }, sort: { fields: data___display_order, order: DESC }) {
            edges {
                node {
                    id: prismicId
                    lang
                    alternate_languages {
                        id
                        lang
                    }
                    data {
                        type_id
                        tag_id
                        series_id
                        faction_id
                        label_type
                        product_name {
                            text
                        }
                        display_order
                        photos {
                            img {
                                url
                            }
                        }
                    }
                }
            }
            totalCount
        }

        allProductsEngExclusive: allPrismicProductDetail(filter: { lang: { eq: "en-gb" } }, sort: { fields: data___display_order, order: DESC }) {
            edges {
                node {
                    id: prismicId
                    data { product_name { text } }
                }
            }
        }

        siteOGImage: file(name: {eq: "og_product"}) {
            publicURL
        }
    }
`
