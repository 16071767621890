import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import {
    Dropdown, Col
} from 'react-bootstrap'

import { withNamespaces } from 'react-i18next'

import LocalImage from './../../components/local-image'

const ptype = require('./../../util/productcatelist.json')

class ProductsMenu extends Component {
    // constructor(props) {
    //     super(props)
    //     // Don't call this.setState() here!
    // }

    render() {

        // console.log(` ==== this.props : ProductsMenu ==== `, this.props )
        // const t = this.props.t
        const lang = this.props.lng
        const mode = this.props.mode
        
        let pageslist = {}
        _.map( ptype, (obj,hash) =>{
            if (obj.serieid ===""){
                pageslist[hash] = {}
                pageslist[hash]["cate"] = this.props.t(obj.nameid)
                pageslist[hash]["subcate"] = []
            }else{
                // if (typeof pageslist[hash] === undefined)
                    // pageslist[hash][1] = obj.nameid
                // let subcate = { hash : obj.nameid }
                // console.log(`subcate = `, subcate)
                // console.log(`${obj.serieid} / pageslist[${obj.serieid}][1][${hash}] = ${obj.nameid} pageslist = `, pageslist)
                // if (typeof pageslist[obj.serieid][1] === undefined ) pageslist[obj.serieid][1] = {}
                pageslist[obj.serieid]["subcate"].push([ hash, this.props.t(obj.nameid) ])
            }
        })
        // console.log(`Mode : ${mode} , pageslist `, pageslist)


        let htmlCompontent = []
        switch (mode) {
            case 'dropdown':
                htmlCompontent.push(
                    <Col key={`products-menu-dropdown-col`} xs={3} className="d-block d-md-none pr-0">
                        <Dropdown className="submenu d-flex justify-content-center" alignRight>
                            <Dropdown.Toggle variant="bookmark">
                                <LocalImage name="btn-down" attr={{className: "icon", alt:"Lang"}} fluid={true}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {_.map( pageslist, (obj,catekey) =>{
                                    let html = []
                                    // console.log(`obj.cat ${obj.cate} subcate `, obj.subcate)
                                    _.map( obj.subcate , (subcate) =>{
                                        html.push(
                                            <Dropdown.Item key={`cate-menu-dropdown-${subcate[0]}`} href={`/${lang}/products/#${subcate[0]}`} className="subcate">&gt; {subcate[1]}</Dropdown.Item>
                                        )
                                    })
                                    return(
                                        <>
                                        <Dropdown.Item key={`cate-menu-dropdown-${catekey}`} href={`/${lang}/products/#${catekey}`} className="cate">{obj.cate}</Dropdown.Item>
                                        {html}
                                        </>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                )
                break

            case 'side':
            default:
                htmlCompontent.push(
                    <aside key={`products-sidemenu`} className="pt-5 d-none d-md-block pl-0">
                        <ul>
                         {_.map( pageslist, (obj,catekey) =>{
                            let html = []
                            _.map( obj.subcate, (subcate) =>{
                                html.push(
                                    <li key={`cate-sidemenu-${subcate[0]}`}><a href={`/${lang}/products/#${subcate[0]}`}> 	&gt; {subcate[1]}</a> </li>
                                )
                            })
                            return(
                                <li key={`cate-${catekey}`}>
                                    <a href={`/${lang}/products/#${catekey}`}>{obj.cate}</a>
                                    <ul>{html}</ul>
                                </li>
                            )
                        })}
                        </ul>
                    </aside>
                )
        }
        return(htmlCompontent)
    }
}

export default withNamespaces()(ProductsMenu)
