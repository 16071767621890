import React, { Component } from 'react'
// import _ from 'lodash'
import {
    // Row, Col, Button,
    CardGroup
} from 'react-bootstrap'
// import { StaticQuery, graphql, Link } from 'gatsby'
// import Moment from 'react-moment'
import { withNamespaces } from 'react-i18next'

import ProductCard from './card'

class ProductsList extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            patternInCol: 'mobile',
            products: {
                mobile: this._makeInPattern([2,2]),
                desktop: this._makeInPattern([3,3])
            }
        }
        this._handleResize = this._handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this._handleResize)
        if (typeof window !== `undefined`) {
            this._handleResize()
        }
    }

    // componentWillUnmount() {
    //     window.removeEventListener(`resize`, this._handleScroll)
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.products !== this.props.products) {
            this.setState({
                products: {
                    mobile: this._makeInPattern([2,2]),
                    desktop: this._makeInPattern([3,3])
                }
            })
        }
    }

    shouldComponentUpdate() {
        return true
    }

    _makeInPattern(colpattern) {
        let products = this.props.products
        // console.log(`_makeInPattern products = `, products)
        let renderedRow = 0
        let patterned = []
        let _p = []

        products.map((product, i) => {
            _p[renderedRow] = (_p[renderedRow] === undefined) ? [] : _p[renderedRow]
            // console.log(`renderedRow: ${renderedRow}`)
            // console.log(`pattern Set: ${this.patternInCol[renderedRow % 2]}`)
            if ((_p[renderedRow].length >= colpattern[renderedRow % 2])) {
                renderedRow++
                _p[renderedRow] = (_p[renderedRow] === undefined) ? [] : _p[renderedRow]
                _p[renderedRow].push(product)
            } else {
                _p[renderedRow].push(product)
            }
            return true
        })
        patterned = _p
        return patterned
    }

    _handleResize() {
        window.requestAnimationFrame(() => {
            if (window.innerWidth < 768) {
                this.setState({
                    patternInCol: 'mobile'
                })
            } else {
                this.setState({
                    patternInCol: 'desktop'
                })
            }
        })
    }

    render() {
        // const t = this.props.t
        const productsList = this.state.products
        const pattern = this.state.patternInCol
        // console.log(`listing render products = this.props.products `, this.props.products)
        // console.log(`listing render products = productsList`, productsList)

        return (
            <>
                {
                    productsList[pattern].map((row, j) => {
                        let hs = (j<this.props.pts) ? ' show ' : 'hidden'
                        return (
                            <CardGroup key={`product-r-${j}`} className={`${hs}`}>
                                {row.map((product, k) => {
                                    return (
                                        <ProductCard {...this.props} key={k} item={product} />
                                    )
                                })}
                            </CardGroup>
                        )
                    })
                }
            </>
        )
    }
}

export default withNamespaces()(ProductsList)
